<template>
  <el-col style="padding-right: 7px; padding-left: 7px">
    <div style="cursor: pointer">
      <el-card :body-style="{ padding: '0px' }" class="card">
        <router-link target="_blank" :to="`/image/${imageAlbum.albumId}`">
          <div class="imgs">
            <el-image
              lazy
              fit="cover"
              :src="imageAlbum.coverUrl"
              class="coverImg"
            />
            <span style="position: absolute; bottom: 0; right: 0; color:white">
              <i class="el-icon-picture-outline">{{imageAlbum.total}}</i>
            </span>
          </div>
        </router-link>
        <div style="padding: 14px">
          <router-link target="_blank" :to="`/image/${imageAlbum.albumId}`">
            <span style="left: 0;margin-bottom: 0px;color: black;">{{ imageAlbum.albumName | ellipsis }}</span>
          </router-link>
        </div>
<!--        <div style="padding: 14px">
          <span style="left: 0;margin-bottom: 0px;color: black;">
            <router-link target="_blank" :to="`/user/${imageAlbum.userId}`"><i class="el-icon-user"> {{ imageAlbum.username }} </i></router-link> · {{ imageAlbum.pubDate }}
          </span>
        </div>-->
      </el-card>
    </div>
  </el-col>
</template>

<script>
import { handleVisited } from 'assets/js/utils'

export default {
  name: 'ImageAlbumCard',
  filters: {
    ellipsis(value) {
      if (!value) return ''
      const max = 20
      if (value.length > max) {
        return value.slice(0, max) + '...'
      }
      return value
    }
  },
  props: {
    imageAlbum: {
      type: Object,
      default: null
    },
    // 时间前的描述
    dateTit: {
      type: String,
      default: ''
    }
  },
  methods: {
    getVisited(visited) {
      return handleVisited(visited)
    }
  }
}
</script>

<style scoped>
.time {
  font-size: 15px;
  color: #999;
}

.bottom {
  margin-top: 13px;
  line-height: 12px;
}

.tit {
  font-weight: 700;
  font-size: 18px;

  height: 50px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /*行数*/
  -webkit-box-orient: vertical;
}

.num {
  position: relative;
  font-size: 15px;
  padding-top: 9px;
}

/*处于手机屏幕时*/
@media screen and (max-width: 768px) {
  .tit {
    font-weight: 600;
    font-size: 12px;
    height: 32px;
  }
  .time {
    font-size: 10px;
    color: #999;
  }
  .num {
    font-size: 9px;
    padding-top: 3px;
  }
  .bottom {
    margin-top: 2px;
    line-height: 7px;
  }
  .coverImg {
    height: 120px !important;
  }
}

.coverImg {
  width: 100%;
  height: 175px;
  display: block;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}

.clearfix:after {
  clear: both;
}

.card {
  margin-bottom: 20px;
  transition: all 0.6s; /*所有属性变化在0.6秒内执行动画*/
}

/*.card:hover {
  !*鼠标放上之后元素变成1.06倍大小*!
  transform: scale(1.06);
}*/
.imgs {
  position: relative;
}
.play-icon {
  position: absolute;
  /*top: -15px;*/
  right: 2%;
  bottom: 5px;
  z-index: 7;
  width: 40px;
}
</style>
