<template>
  <div>
    <el-row class="movie-list">
      <el-col v-if="user" :md="24">
        <el-card :body-style="{ padding: '0px' }" class="card">
          <div slot="header" class="clearfix">
            <el-row>
              <el-col :md="1">
                <el-avatar>
                  <el-image :src="user.avatarUrl" />
                </el-avatar>
              </el-col>
              <el-col :md="22">
                <span>{{ user.screenName }}</span>
                <span v-html="'&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;'" />
                <el-button
                  type="danger"
                  size="mini"
                  :icon="followButton.icon"
                  @click="followUser(user.userId)"
                >
                  <span>{{ followButton.text }}</span>
                </el-button>
                <el-button
                  type="danger"
                  size="mini"
                  icon="el-icon-message"
                  @click="sendMessage(user.userId)"
                >
                  <span>发消息</span>
                </el-button>
                <el-tag v-if="user.biliUserId !== undefined && user.biliUserId !== null" size="mini" type="'success'" disable-transitions>
                  <a target="_blank" :href="`https://space.bilibili.com/` + user.biliUserId">bili</a>
                </el-tag>
              </el-col>
              <el-col :md="1">
                <StampBadge
                  v-if="user.vip"
                  style="position: relative; top: 0; right: 0"
                  size="small"
                  color="warning"
                  content="小会员"
                  :rotate="0"
                />
              </el-col>
            </el-row>
            <el-row>
              <span v-if="user.signature !== null" v-html="user.signature" />
              <span v-if="user.signature === undefined || user.signature === null">-</span>
            </el-row>
            <el-row>
              <br>
              <router-link target="_blank" :to="`/user/${user.userId}/following`">
                <span class="el-icon-user">关注数: {{ user.following }}</span>
              </router-link>
              <span v-html="'&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;'" />
              <router-link target="_blank" :to="`/user/${user.userId}/follower`">
                <span class="el-icon-user">粉丝数: {{ user.follower }}</span>
              </router-link>
            </el-row>
          </div>
        </el-card>
      </el-col>
    </el-row>
    <el-row>
      <el-col :md="24" class="movie-list">
        <el-tabs v-if="userContentData !== null" v-model="activeName" @tab-click="tabClick">
          <el-tab-pane name="video">
            <span slot="label">
              视频<el-badge :value="userContentData.videoCount" :max="9999" class="item" type="warning" />
            </span>
            <div v-if="activeName === 'video'">
              <el-col v-for="(video, index) in dataList" :key="index" :md="6" :sm="12" :xs="12">
                <video-card :video="video" />
              </el-col>
            </div>
          </el-tab-pane>
          <el-tab-pane name="image">
            <span slot="label">
              图片<el-badge :value="userContentData.imageCount" :max="9999" class="item" type="warning" />
            </span>
            <div v-if="activeName === 'image'">
              <el-col v-for="(album, index) in dataList" :key="index" :md="6" :sm="12" :xs="12">
                <image-album-card :image-album="album" />
              </el-col>
            </div>
          </el-tab-pane>
          <el-tab-pane name="album">
            <span slot="label">
              播放列表<el-badge :value="userContentData.albumCount" :max="9999" class="item" type="warning" />
            </span>
            <div v-if="activeName === 'album'">
              <el-col
                v-for="(item, index) in dataList"
                :key="index"
                :md="6"
                :sm="12"
                :xs="12"
                style="padding-right: 5px; padding-left: 5px; padding-bottom: 5px"
              >
                <el-card :body-style="{ padding: '0px' }" class="card">
                  <div class="imgs" style="cursor: pointer" :title="item.albumName">
                    <router-link style="text-decoration-line: none" target="_blank" :to="`/playlist/${item.albumId}`">
                      <el-image
                        lazy
                        fit="cover"
                        class="coverImg"
                        :src="item.coverUrl"
                      />
                      <span class="el-icon-files" style="position: absolute; bottom: 0; right: 10%; color:red">
                        {{ item.total }}
                      </span>
                    </router-link>
                  </div>
                  <div style="padding: 14px">
                    <router-link style="text-decoration-line: none" target="_blank" :to="`/playlist/${item.albumId}`">
                      <span style="left: 0;margin-bottom: 0px;color: black;">{{ item.albumName | ellipsis }}</span>
                    </router-link>
                  </div>
                </el-card>
              </el-col>
            </div>
          </el-tab-pane>
        </el-tabs>
      </el-col>
      <el-col :span="24" class="pagination">
        <el-pagination
          background
          :small="screenWidth <= 768"
          hide-on-single-page
          layout="prev, pager, next"
          :page-size="pageSize"
          :current-page="currentPage"
          :total="totalSize"
          @current-change="handleCurrentChange"
          @prev-click="handleCurrentChange"
          @next-click="handleCurrentChange"
        />
      </el-col>
    </el-row>
    <el-row v-if="showEmpty" class="not-result">
      <el-col :span="12" :offset="6">
        <img src="@/assets/img/icon/not-collection.png">
        <div>该用户还没发布任何东西呢</div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import StampBadge from '@/components/StampBadge'
import VideoCard from '@/components/card/VideoCard'
import ImageAlbumCard from '@/components/card/ImageAlbumCard'

import { getUserInfo, checkRelation, followUser, unfollowUser } from '@/api/user'
import { getUserContentData, getUserVideos } from '@/api/video'
import { getAlbumImage1 } from '@/api/image'
import { getUserPlaylist } from '@/api/collect'

export default {
  name: 'Home',
  filters: {
    ellipsis(value) {
      if (!value) return ''
      const max = 15
      if (value.length > max) {
        return value.slice(0, max) + '...'
      }
      return value
    },
    ellipsisUsername(value) {
      if (!value) return ''
      const max = 10
      if (value.length > max) {
        return value.slice(0, max) + '...'
      }
      return value
    }
  },
  components: { StampBadge, VideoCard, ImageAlbumCard },
  data() {
    return {
      // 屏幕宽度, 为了控制分页条的大小
      screenWidth: document.body.clientWidth,
      user: null,
      userId: null,
      followButton: {
        icon: 'el-icon-plus',
        text: '关注'
      },
      activeName: 'video',
      currentPage: 1,
      pageSize: 12,
      totalSize: 0,
      dataList: [],
      queryInfo: {
        userId: null,
        pn: 1
      },
      showEmpty: true,
      userContentData: null
    }
  },
  watch: {
    $route() {
      this.$router.go()
    }
  },
  created() {
    this.userId = this.$route.params.id
    this.queryInfo.userId = this.userId
    getUserInfo(this.userId).then(resp => {
      if (resp.code === 0) {
        this.user = resp.data
        const path = this.$route.path
        if (path.endsWith('video')) {
          this.activeName = 'video'
          document.title = this.user.screenName + '的视频'
        } else if (path.endsWith('image')) {
          this.activeName = 'image'
          document.title = this.user.screenName + '的图片'
        } else if (path.endsWith('album')) {
          this.activeName = 'album'
          document.title = this.user.screenName + '的播放列表'
        } else {
          document.title = this.user.screenName + '的主页'
        }
        this.getData()
      }
    })
    checkRelation(this.userId).then(resp => {
      if (resp.code === 0) {
        if (resp.data) {
          this.followButton.text = '已关注'
          this.followButton.icon = 'el-icon-check'
        }
      }
    })
    getUserContentData(this.userId).then(resp => {
      if (resp.code === 0) {
        this.userContentData = resp.data
      }
    })
  },
  mounted() {
    // 当窗口宽度改变时获取屏幕宽度
    window.onresize = () => {
      return () => {
        window.screenWidth = document.body.clientWidth
        this.screenWidth = window.screenWidth
      }
    }
  },
  methods: {
    followUser(userId) {
      if (this.followButton.text === '关注') {
        followUser(userId).then(resp => {
          if (resp.code === 0) {
            this.followButton.text = '已关注'
            this.followButton.icon = 'el-icon-check'
          }
        })
      } else {
        unfollowUser(userId).then(resp => {
          if (resp.code === 0) {
            this.followButton.text = '关注'
            this.followButton.icon = 'el-icon-plus'
          }
        })
      }
    },
    sendMessage(userId) {
      this.$message.info('暂未实现')
    },
    handleCurrentChange(pageNumber) {
      this.currentPage = pageNumber
      this.queryInfo.pn = pageNumber
      this.getData()
      // 回到顶部
      scrollTo(0, 0)
    },
    tabClick(tab) {
      this.dataList = []
      this.activeName = tab.name
      this.goToTab(this.activeName)
    },
    goToTab(activeName) {
      const path = '/user/' + this.userId + '/' + activeName
      if (this.$route.path === path) {
        this.$router.go(0)
        return
      }
      this.$router.push(path)
    },
    getData() {
      this.dataList = []
      if (this.activeName === 'video') {
        this.userVideoListWrapper(this.currentPage, this.userId)
      } else if (this.activeName === 'image') {
        this.userImageWrapper(this.currentPage, this.userId)
      } else if (this.activeName === 'album') {
        this.userAlbumWrapper(this.currentPage, this.userId)
      }
    },
    userVideoListWrapper(pageNumber, userId) {
      getUserVideos(userId, pageNumber).then(resp => {
        if (resp.code === 0) {
          const respData = resp.data
          this.dataList = respData.list
          this.totalSize = respData.totalSize
          this.showEmpty = this.dataList.length === 0
        }
      })
    },
    userImageWrapper(pageNumber, userId) {
      getAlbumImage1(pageNumber, userId).then(resp => {
        if (resp.code === 0) {
          const respData = resp.data
          this.dataList = respData.list
          this.totalSize = respData.totalSize
          this.showEmpty = this.dataList.length === 0
        }
      })
    },
    userAlbumWrapper(pageNumber, userId) {
      getUserPlaylist(this.queryInfo).then(resp => {
        if (resp.code === 0) {
          const respData = resp.data
          this.dataList = respData.list
          this.totalSize = respData.totalSize
          this.showEmpty = this.dataList.length === 0
        }
      })
    }
  }
}
</script>

<style scoped>
.movie-list {
  padding-top: 15px;
  padding-left: 6%;
  padding-right: 6%;
}

.pagination {
  text-align: center;
  padding: 10px;
}

/*处于手机屏幕时*/
@media screen and (max-width: 768px){
  .movie-list {
    padding-top: 8px;
    padding-left: 0.5%;
    padding-right: 0.5%;
  }
  .coverImg {
    height: 120px !important;
  }
}

.not-result {
  padding-top: 100px;
  padding-bottom: 100px;
  text-align: center;
}

.item {
  margin-top: 10px;
  margin-right: 40px;
}

.coverImg {
  width: 100%;
  height: 90px;
  display: block;
}

.imgs {
  position: relative;
}
</style>
